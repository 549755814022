export const FORM_LAY_OUT = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export const PRESELL_OPTIONS = [
  {
    label: '24小时',
    value: '86400',
  },
  {
    label: '48小时',
    value: '172800',
  },
];
