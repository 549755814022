import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData, PaginationData } from 'egenie-common';
import { request } from 'egenie-utils';
import _ from 'lodash';
import { observable, action, flow, toJS } from 'mobx';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React from 'react';
import { api } from '../../../utils';
import { scrollToAnchor } from '../../../utils/tools';
import { MainImgsStore, ProductInfoStore, sliceStrLen, dealOversizeImg, charaRegGlobal, getImageInfo, getImageUrl, dealUnlegalImg, dealGoodsProps } from '../../shelvesBase';
import type { IStyleImgRes } from '../interface';
import { AnchorStore } from './anchorStore';
import { SizeColorStore } from './priceStock/saleInfo/sizeColorStore';
import { UploadIVStore } from './uploadStore';

export class StepTwoStore {
  constructor(parent) {
    this.parent = parent;
  }

  public parent;
  
  public mainImgsStore = new MainImgsStore();

  public singleUploadImgStore = new UploadIVStore(this);

  public sizeColorStore = new SizeColorStore(this);

  public productInfoStore = new ProductInfoStore(this);

  public anchorStore = new AnchorStore(this);

  @observable public logisticsTemplateOptions = [];// 运费模版

  @observable public resultVisilbe = false;// 结果弹窗是否展示

  @observable public goodsProps = [];

  @observable public shopName = '';// 店铺名称

  @observable public productNameHasFilled = 0;

  @observable public formRef = React.createRef<FormInstance>();

  @observable public listingMode = 0;// 是否立即上架

  @observable public submitResult = '';// 提交信息

  @observable public errorMessage = '';// 错误信息

  @observable public whitePics = [];// 白底图

  @observable public materialPics = [];// 透明图

  @observable public goodsId = '';// 上架到平台的商品id

  @observable public refundInfo = {
    allergyRefund: 0, // 过敏包退
    brokenRefund: 0, // 破损包退
    freshRotRefund: 0, // 坏了包退
  };// 包退信息

  @observable public refundOptionList = [];// 退货规则列表

  public presaleTime = 0;// 最大预售天数

  @observable public isPresale = false;// 是否预售

  @observable public brandFetching = false;// 是否loading品牌

  @observable public brandPage = 1;

  @observable public brandName = '';// 品牌名

  @observable public brandOptions = [];// 品牌选项

  // 查询品牌选项接口
  public getPropsOptions = (propId: string, page: number, brandName?: string) => {
    return request<PaginationData>({
      url: '/api/gms/goods/platform/ks/searchPropValues',
      method: 'POST',
      data: {
        categoryId: this.parent.categoryId,
        shopId: this.parent.shopId,
        propId,
        page,
        pageSize: 50,
        propValueName: brandName,
      },
    });
  };

  // 商品标题变化
  public handleProductChange = (e) => {
    const productName = e.target.value.replace(charaRegGlobal, 'rr');
    this.productNameHasFilled = productName.length < 30 ? productName.length : 30;
    if (productName.length > 30) {
      const goodsName = sliceStrLen(e.target.value, 30);
      this.formRef?.current.setFieldsValue({ goodsName });
    }
  };

  // 品牌选项下拉
  public handleBrandScroll = flow(function* () {
    this.brandPage++;
    if (this.brandPage > this.totalPageCount) {
      return;
    }
    try {
      const res = yield this.getPropsOptions('102', this.brandPage, this.searchBrandName);
      const { totalPageCount, list, page } = res.data;
      this.totalPageCount = totalPageCount;
      this.brandPage = page;
      this.brandOptions = this.brandOptions.concat(this.parent.mapOptions(list, 'key', 'value'));
    } catch (e) {
      console.log('查找品牌选项出错', e);
    }
  });

  // 查找品牌选项
  public handleSearchBranch = flow(function* (brandName) {
    this.brandPage = 1;
    this.brandFetching = true;
    this.searchBrandName = brandName;
    try {
      const res = yield this.getPropsOptions('102', this.brandPage, this.searchBrandName);
      this.totalPageCount = res.data?.totalPageCount || 0;
      this.brandOptions = this.parent.mapOptions(res.data?.list, 'key', 'value');
    } catch (e) {
      console.log('查找品牌选项出错', e);
    } finally {
      this.brandFetching = false;
    }
  });

  public closeTab = () => {
    window.top.egenie.closeTab(this.parent.pageId);
  };

  public formItemOnChange = (key, val, index) => {
    //
  };

  // 切换预售/不预售
  @action public onPresaleChange = (flag: boolean) => {
    this.isPresale = flag;
  };

  @action public closeResultModal = () => {
    this.resultVisilbe = false;
  };

  // 填充第二步表单内容
  public initFormData = flow(function* (data) {
    const { goodsBase, platformExtends, goodsProps, goodsPic, saleProps, goodsPropPicList, goodsSkuList } = data;
  
    const { goodsName, salePrice } = goodsBase;
    const { shortTitle, details, expressTemplateId, refundRule, allergyRefund, brokenRefund, freshRotRefund, saleTimeFlag, timeOfSale, immediatelyOnOfflineFlag, promiseDeliveryTime, deliverGoodsInteralTime } = platformExtends;

    // 初始化商品标题字数
    const productName = goodsName.replace(charaRegGlobal, 'rr');
    this.productNameHasFilled = productName.length;

    // 获取品牌
    this.brandPage = 1;
  
    let brandOptions;

    // 已有的品牌
    const currentBrandName = goodsProps.find((item) => item.id === '102')?.valueName;

    this.searchBrandName = (currentBrandName === '其他/other' ? '其他' : currentBrandName) || '';
    
    try {
      // 类目为品牌 选项分页获取
      const brandRes = yield this.getPropsOptions('102', this.brandPage, this.searchBrandName);
      brandOptions = this.parent.mapOptions(brandRes.data?.list, 'key', 'value') || [];
    } catch (e) {
      console.log('获取品牌出错', e);
    }

    // 年份季节倒序排列
    let requiredProps = 0;

    goodsProps.forEach((item) => {
      if (item.required) {
        requiredProps++;
      }

      // 补丁代码:类目为上市季节年份 按照最新年份倒序排列
      if (item.id === '272') {
        item.checkOptions = _.sortBy(item.checkOptions, (o) => Number(o.value.slice(0, 4))).reverse();
      }

      // 品牌分页获取
      if (item.id === '102') {
        this.brandOptions = brandOptions;
      }
    });

    // 商品属性排序(必填项放在最前面)
    this.goodsProps = dealGoodsProps(goodsProps);
    
    // 必填信息数
    this.anchorStore.allTitles.baseinfo.requiredNum = requiredProps + 1;

    // 取前9张
    const mainPics = goodsPic.mainPics.slice(0, 15);

    // 处理主图
    try {
      for (let i = 0; i < mainPics.length; i++) {
        goodsPic.mainPics[i] = yield this.dealMainImg(goodsPic.mainPics[i], true);
      }
  
      // 回写主图
      this.mainImgsStore.writeBackMainImgs(goodsPic);
    } catch (e) {
      console.log('处理主图出错', e);
    }

    // 取前20张
    const detailPics = goodsPic.detailPics.slice(0, 20);
    
    // 处理商品详情图
    try {
      for (let i = 0; i < detailPics.length; i++) {
        goodsPic.detailPics[i] = yield this.dealMainImg(goodsPic.detailPics[i], false);
      }
  
      this.productInfoStore.writeBackDetailImgs(goodsPic);// 回写商品详情
    } catch (e) {
      console.log('处理详情图出错', e);
    }

    // 处理透明图
    try {
      if (goodsPic.materialPics.length > 0) {
        const materialPics = yield this.dealMaterialImg(goodsPic.materialPics[0]);
        this.materialPics = [
          {
            key: 'materialPics',
            url: materialPics,
          },
        ];
      }
    } catch (e) {
      console.log('处理透明图出错', e);
    }

    // 处理白底图
    try {
      if (goodsPic.whitePics.length > 0) {
        const whitePics = yield this.dealMaterialImg(goodsPic.whitePics[0]);
        this.whitePics = [
          {
            key: 'whitePics',
            url: whitePics,
          },
        ];
      }
    } catch (e) {
      console.log('处理白底图出错', e);
    }

    // 处理主图视频
    if (goodsPic.videoManageId) {
      this.singleUploadImgStore.videoFileList = [
        {
          url: goodsPic.videoUrl,
          id: goodsPic.videoManageId,
        },
      ];
    }

    // 初始化颜色尺码
    this.sizeColorStore.initData(saleProps, goodsPropPicList, goodsSkuList);

    // 是否预售
    this.isPresale = Boolean(deliverGoodsInteralTime);

    // 划线价
    let initSalePrice;
    if (goodsSkuList?.length > 0) {
      initSalePrice = Math.max(...goodsSkuList.map((item) => item.salePrice)) + 1;
    } else {
      initSalePrice = 1;
    }

    const dealedShortTitle = sliceStrLen(goodsName, 10);

    const duration = moment.duration({ minutes: 15 });

    // 回写表单内容
    this.formRef?.current.setFieldsValue({
      goodsName,
      shortTitle: shortTitle ?? dealedShortTitle,
      details,
      salePrice: initSalePrice,
      expressTemplateId: expressTemplateId ?? this.logisticsTemplateOptions[0]?.value, // 运费模板默认选择第一个
      refundRule: refundRule ?? '1',
      allergyRefund: allergyRefund ?? true,
      brokenRefund: brokenRefund ?? true,
      freshRotRefund: freshRotRefund ?? true,
      saleTimeFlag: Boolean(saleTimeFlag),
      deliverGoodsInteralTime: deliverGoodsInteralTime ? deliverGoodsInteralTime / 3600 / 24 : 3,
      promiseDeliveryTime: promiseDeliveryTime ?? '86400',
      timeOfSale: timeOfSale ? moment(timeOfSale) : moment().add(duration),
      immediatelyOnOfflineFlag: immediatelyOnOfflineFlag ?? 0,
    });
  });

  // 上架规则初始化
  @action public initConfigRule = (data) => {
    const { allergyRefund, brokenRefund, freshRotRefund, preSaleTime, refundOptionList } = data;

    // 包退信息
    this.refundInfo = {
      allergyRefund: Number(allergyRefund),
      brokenRefund: Number(brokenRefund),
      freshRotRefund: Number(freshRotRefund),
    };

    this.presaleTime = preSaleTime;

    // 退货规则列表
    this.refundOptionList = this.parent.mapOptions(refundOptionList, 'key', 'value');
  };

  // 组装提交信息
  private releaseData = async() => {
    // 收集信息
    const data = this.formRef.current?.getFieldsValue();
    const { goodsName, shortTitle, salePrice, details, expressTemplateId, refundRule, immediatelyOnOfflineFlag, saleTimeFlag, timeOfSale, deliverGoodsInteralTime, promiseDeliveryTime, brokenRefund } = data;
    const goodsBase = {
      goodsName,
      salePrice,
      goodsCategoryId: this.parent.categoryId,
      goodsCategoryIds: this.parent.categoryIds,
      goodsCategoryFullName: this.parent.categoryName.replace(/>/g, ','),
    };

    // 图片信息
    const goodsPicVo = {
      mainPics: this.mainImgsStore.mainPics.map((item) => item.url),
      whitePics: this.whitePics.length > 0 ? this.whitePics.map((item) => item.url) : [],
      materialPics: this.materialPics.length > 0 ? this.materialPics.map((item) => item.url) : [],
      detailPics: this.productInfoStore.imgs.length > 0 ? this.productInfoStore.imgs.map((item) => item.url) : [],
    };

    // 处理视频
    if (this.singleUploadImgStore.videoFileList?.length > 0) {
      const { url, id } = this.singleUploadImgStore.videoFileList[0];
      Object.assign(goodsPicVo, {
        videoUrl: url,
        videoManageId: id,
      });
    }

    const goodsSkuSetOnShelfRequestList = this.sizeColorStore.goodsSkuList;

    // 处理sku图片比例为1:1
    for (let i = 0; i < goodsSkuSetOnShelfRequestList.length; i++) {
      if (goodsSkuSetOnShelfRequestList[i].picUrl) {
        goodsSkuSetOnShelfRequestList[i].picUrl = await this.dealMainImg(goodsSkuSetOnShelfRequestList[i].picUrl, true);
      }
    }
    
    const checkedColorList = (this.sizeColorStore.colorList).filter((item) => item.checked);
    const { colorPropName } = this.sizeColorStore;
    const goodsPropPicVos = checkedColorList.map((item) => ({
      url: item.url,
      properties: `${colorPropName}:${item.name}`,
      smallPicUrl: '',
    }));

    // 处理sku图片比例为1:1
    for (let i = 0; i < goodsPropPicVos.length; i++) {
      if (goodsPropPicVos[i].url) {
        goodsPropPicVos[i].url = await this.dealMainImg(goodsPropPicVos[i].url, true);
      }
    }

    // 商品属性
    let goodsProps = this.goodsProps.map((cur) => {
      const { id, type, name, checkOptions } = cur;
      const values = data;
      let eachValue;
      let eachValueName;

      if (type !== 'INPUT') {
        eachValue = typeof values[id] === 'object' ? (values[id] && values[id].join(',')) : values[id];

        // 品牌
        if (id === '102') {
          eachValueName = this.brandOptions.find((item) => item.value === eachValue)?.label;
        } else {
          // 其他select框
          if (typeof values[id] === 'object') {
            const eachValueNames = checkOptions.filter((item) => values[id].includes(item.key));
            eachValueName = eachValueNames.map((item) => item.value)?.join(',');
          } else {
            eachValueName = checkOptions.find((item) => item.key === eachValue)?.value;
          }
        }
      } else {
        eachValue = values[id];
        eachValueName = values[id];
      }

      return {
        id,
        name,
        type,
        value: eachValue,
        valueName: eachValueName,
      };
    });

    // 过滤掉没有值的商品属性
    goodsProps = goodsProps.filter((item) => {
      return item.value;
    });
    
    const platformExtends = {
      payWay: 2, // 在线支付
      deliveryMethod: 'logistics',
      refundRule,
      immediatelyOnOfflineFlag,
      shortTitle,
      details,
      expressTemplateId,
      deliverGoodsInteralTime: deliverGoodsInteralTime * 24 * 3600, // 预售发货时间(转换为s)
      promiseDeliveryTime, // 非预售承诺发货时间
      saleTimeFlag,
      timeOfSale: timeOfSale ? moment(timeOfSale).format('YYYY-MM-DD HH:mm:ss') : '',
      goodsProps,
      brokenRefund,
    };

    const submitData = {
      gmsGoodsId: this.parent.gmsGoodsId,
      goodsBase,
      platformExtends,
      goodsPicVo,
      goodsSkuSetOnShelfRequestList,
      goodsPropPicVos,
    };

    return submitData;
  };

  // 获取主图款式库图片
  public getPosGoodsGroupPics = (): void => {
    request<BaseData<IStyleImgRes[]>>({
      url: api.getPosGoodsGroupPics,
      method: 'POST',
      data: { gmsGoodsId: this.parent.gmsGoodsId },
    }).then(async(res) => {
      const styleList = res.data[0].mainPicUrls;
      for (let i = 0; i < styleList.length; i++) {
        try {
          const url = await this.dealMainImg(styleList[i], true);
          this.mainImgsStore.styleImgLists.push({
            id: nanoid(),
            imgId: nanoid(),
            url,
          });
        } catch (e) {
          console.log('获取主图款式库图片出错', e);
        }
      }
    })
      .catch(() => {
        this.mainImgsStore.styleImgLists = [];
      });
  };

  // 处理主图
  public dealMainImg = async(url: string, dealRatio?: boolean) => {
    const { Format, FileSize, ImageWidth, ImageHeight } = await getImageInfo(url);

    let newUrl = dealUnlegalImg(url, Format.value);

    // 处理图片尺寸 比例为1:1
    if (dealRatio && ImageWidth.value !== ImageHeight.value) {
      const resizeWidth = Math.min(Number(ImageWidth.value), Number(ImageHeight.value));
      const resizeHeight = resizeWidth;
      newUrl = getImageUrl(url, resizeWidth, resizeHeight, ImageWidth, ImageHeight);
    }

    const fileSize = Number(FileSize.value) / 1024 / 1024;
    if (fileSize > 2) {
      newUrl = dealOversizeImg(newUrl, Format.value);
    }
    return newUrl;
  };

  // 处理透明图
  private dealMaterialImg = async(url) => {
    const { ImageWidth, ImageHeight, Format } = await getImageInfo(url);
    let newUrl = getImageUrl(url, 800, 800, ImageWidth, ImageHeight);
    newUrl = dealUnlegalImg(newUrl, Format.value);
    return newUrl;
  };

  // 保存上架信息
  @action public saveInfo = async() => {
    this.anchorStore.isValid = 'init';
    if (!this.sizeColorStore.saveValidate()) {
      scrollToAnchor('priceStock');
      return;
    }
    this.parent.loading = true;
    const data = await this.releaseData();
    request<BaseData>({
      url: '/api/gms/goods/platform/ks/saveGoodsPutOnShelf',
      method: 'POST',
      data,
    }).then((res) => {
      message.success(res.data || '保存成功');
    })
      .finally(() => {
        this.parent.loading = false;
      });
  };

  // 提交上架信息
  public submitInfo = flow(function* () {
    if (!this.sizeColorStore.saveValidate()) {
      scrollToAnchor('priceStock');
      return;
    }
    yield this.anchorStore.getIncomplete();

    const allTitles = this.anchorStore.allTitles;
    const item = Object.keys(allTitles);
    for (let i = 0; i < Object.keys(allTitles).length; i++) {
      if (allTitles[item[i]].hasFilled < allTitles[item[i]].requiredNum) {
        scrollToAnchor(allTitles[item[i]].id);
        return;
      }
    }

    const data = yield this.releaseData();

    this.parent.loading = true;
    this.submitResult = 'inProgress';
    this.resultVisilbe = true;
    request<BaseData<any>>({
      url: '/api/gms/goods/platform/ks/goodsPutonShelf',
      method: 'POST',
      data,
    }).then((res) => {
      const { shopName, upStatus, reason, platformId } = res.data;
      this.shopName = shopName;
      this.errorMessage = reason;
      this.goodsId = platformId;
      this.submitResult = upStatus ? 'Successful' : 'Failed';
    })
      .finally(() => {
        this.parent.loading = false;
      });
  });
}

