import { Form, Radio, Select, Switch, InputNumber, DatePicker, Checkbox } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { ContentWrap } from '../../shelvesBase';
import { PRESELL_OPTIONS } from '../constant';
import styles from './index.less';
import type { StepTwoStore } from './store';

const { Item } = Form;

export const ServiceConventions: React.FC<{ store: StepTwoStore; }> = observer((props) => {
  const { logisticsTemplateOptions, refundOptionList } = props.store;
  return (
    <ContentWrap
      id="serviceConventions"
      style={{ borderBottom: 'none' }}
      text="服务与履约"
    >
      <Item
        label="发货方式"
        name="deliveryMethod"
        required
      >
        <Radio
          checked
          value="logistics"
        >
          物流配送
        </Radio>
      </Item>
    
      <PresaleInfo store={props.store}/>
      <Item
        label="运费模版"
        required
      >
        <div className={styles.preSaleTime}>
          <Item
            name="expressTemplateId"
            noStyle
            rules={[{ required: true }]}
          >
            <Select
              options={logisticsTemplateOptions}
              style={{ width: '180px' }}
            />
          </Item>
          <div
            className={styles.refreshButton}
            onClick={props.store.parent?.getlogisticTem}
          >
            <i className={`icon-replace ${styles.refreshIcon}`}/>
            刷新
          </div>
        </div>
      </Item>
      <Item
        initialValue="1"
        label="退款规则"
        name="refundRule"
        rules={[{ required: true }]}
      >
        <Select
          options={refundOptionList}
          style={{ width: '180px' }}
        />
      </Item>
      <RefundInfo store={props.store}/>
      <Item
        label="上架时间"
        name="immediatelyOnOfflineFlag"
      >
        <Radio.Group>
          <Radio value={0}>
            立即上架
          </Radio>
          <Radio value={1}>
            放入仓库
          </Radio>
        </Radio.Group>
      </Item>
      <SaleTimeInfo/>
    </ContentWrap>
  );
});

function disabledDate(current) {
  return current < moment().subtract(1, 'days') || current > moment().add(30, 'days');
}

// 服务承诺信息
const RefundInfo: React.FC<{ store: StepTwoStore; }> = observer((props) => {
  const { allergyRefund, brokenRefund, freshRotRefund } = props.store.refundInfo;
  return (
    <Item
      label="服务承诺"
    >
      {
        allergyRefund > 0 && (
          <Item
            name="allergyRefund"
            noStyle
            valuePropName="checked"
          >
            <Checkbox
              disabled={allergyRefund == 2}
            >
              过敏包退
            </Checkbox>
          </Item>
        )
      }
      {
        brokenRefund > 0 && (
          <Item
            name="brokenRefund"
            noStyle
            valuePropName="checked"
          >
            <Checkbox
              disabled={brokenRefund == 2}
            >
              破损包退
            </Checkbox>
          </Item>
        )
      }
      {
        freshRotRefund > 0 && (
          <Item
            name="freshRotRefund"
            noStyle
            valuePropName="checked"
          >
            <Checkbox
              disabled={freshRotRefund == 2}
            >
              坏了包退
            </Checkbox>
          </Item>
        )
      }
    </Item>
  );
});

// 预售信息
const PresaleInfo: React.FC<{ store: StepTwoStore; }> = observer((props) => {
  const { isPresale, onPresaleChange, presaleTime } = props.store;
  return (
    <>
      <Item
        label="商品预售"
        valuePropName="checked"
      >
        <Switch
          checked={isPresale}
          onChange={onPresaleChange}
        />
      </Item>
      {
        isPresale ? (
          <Item
            label="预售发货时间"
            required
            rules={[{ required: true }]}
          >
            <div className={styles.preSaleTime}>
              付款后
              <Item
                name="deliverGoodsInteralTime"
                noStyle
              >
                <InputNumber
                  max={presaleTime || 15}
                  min={3}
                  precision={0}
                />
              </Item>
              天内发货
            </div>
          </Item>
        ) : (
          <Item
            label="承诺发货时间"
            name="promiseDeliveryTime"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              {
                PRESELL_OPTIONS.map((item) => (
                  <Radio
                    key={item.value}
                    value={item.value}
                  >
                    {item.label}
                  </Radio>
                ))
              }
            </Radio.Group>
          </Item>
        )
      }
    </>
  );
});

// 定点开售
const SaleTimeInfo = () => {
  return (
    <>
      <Item
        label="定点开售"
        name="saleTimeFlag"
        required
        valuePropName="checked"
      >
        <Switch/>
      </Item>
      <Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => (prevValues.saleTimeFlag !== currentValues.saleTimeFlag)}
      >
        {
          ({ getFieldValue }) => {
            return getFieldValue('saleTimeFlag') ? (
              <Item
                label="开售时间"
                name="timeOfSale"
                required
              >
                <DatePicker
                  disabledDate={disabledDate}
                  showTime
                />
              </Item>
            ) : null;
          }
        }
      </Item>
    </>
  );
};
