import { Form, Input, Col, Select, Spin, Radio } from 'antd';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { ContentWrap, calcStrLen } from '../../shelvesBase';
import { FORM_LAY_OUT } from '../constant';
import styles from './index.less';
import type { StepTwoStore } from './store';

const { Item } = Form;
const { Option } = Select;

export const BaseInfo: React.FC<{ store: StepTwoStore; }> = observer((props: { store; }) => {
  const { productNameHasFilled, handleProductChange, productName } = props.store;
  return (
    <ContentWrap
      id="baseInfo"
      text="基本信息"
    >
      <Item
        label="商品标题"
        name="goodsName"
        rules={
          [
            {
              required: true,
              message: '请输入商品标题',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if ((!value || (value && calcStrLen(value) < 31))) {
                  return Promise.resolve();
                }
                return Promise.reject('商品标题最多输入30个字符');
              },
            }),
          ]
        }
      >
        <Input
          onChange={handleProductChange}
          placeholder="商品标题组成：商品描述+规格 最多输入15个汉字"
          suffix={`${productNameHasFilled}/30`}
        />
      </Item>
      <Item
        label="商品短标题"
        name="shortTitle"
      >
        <Input/>
      </Item>
      <ProductProp store={props.store}/>
      <Item
        label="支付方式"
        required
      >
        <Radio checked>
          在线支付
        </Radio>
      </Item>
    </ContentWrap>
  );
});

// 商品属性
const ProductProp: React.FC<{ store: StepTwoStore; }> = observer((props) => {
  const { goodsProps, formItemOnChange, brandFetching, brandOptions } = props.store;
  return (
    <Item label="商品属性">
      <div className={styles.itemWrap}>
        {goodsProps && goodsProps.map((item, index) => {
        // ... 在此处不同布局的判断
          const key = item.id;
          return (
            <Col
              className={item.className || ''}
              key={key}
              span={item.colspan || 12}
            >
              <Item
                {...(item.itemLayout || FORM_LAY_OUT)}
                initialValue={item.type === 'INPUT' ? item.value : (item.type === 'MULTICHECK' ? (item.value ? item.value.split(',') : undefined) : (item.value || undefined))}
                label={item.name}
                name={key}
                normalize={(value, prevValue, allValues) => {
                  if (item.type === 'MULTICHECK') {
                    if (typeof value === 'string') {
                      return value.split(',');
                    }
                  }
                  return value;
                }}
                rules={[
                  item.rules || {
                    required: item.required,
                    message: item.message,
                  },
                ]}
              >
                {item.type === 'INPUT' ? (
                  <Input
                    autoComplete="off"
                    className={item.className || ''}
                    id={key}
                    placeholder={item.placeholder || '请输入'}
                  />
                ) : (

                  // 品牌的话分页选项返回
                  item.id === '102' ? (
                    <Select
                      allowClear
                      filterOption={false}
                      notFoundContent={brandFetching ? <Spin size="small"/> : '无数据'}
                      onClear={() => {
                        props.store.handleSearchBranch('');
                      }}
                      onPopupScroll={(e) => {
                        e.persist();
                        const { target } = e;
                        if ((target as any).scrollTop + (target as any).offsetHeight >= (target as any).scrollHeight) {
                          props.store.handleBrandScroll();
                        }
                      }}
                      onSearch={(debounce((val) => {
                        props.store.handleSearchBranch(val);
                      }, 500))}
                      options={brandOptions}
                      placeholder="请选择"
                      showSearch
                    />
                  ) : (
                    <Select
                      allowClear={item.type !== 'INPUT'}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      mode={item.type === 'MULTICHECK' ? 'multiple' : null}
                      notFoundContent={item.fetching ? <Spin size="small"/> : '无数据'}
                      onChange={(val) => {
                        formItemOnChange(key, val, index);
                      }}
                      placeholder="请选择"
                      showSearch
                    >
                      {item.checkOptions && item.checkOptions.map((v) => {
                        return (
                          <Option
                            key={v.key}
                            value={v.key}
                          >
                            {v.value}
                          </Option>
                        );
                      })}
                    </Select>
                  )
                )}
              </Item>
            </Col>
          );
        })}
      </div>
    </Item>
  );
});
