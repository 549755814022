import { Row, Col, Input, Form } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ContentWrap, MainImgs, ProductInfo, SingleUploadImg, SingleUploadVideo } from '../../shelvesBase';
import styles from './index.less';
import type { StepTwoStore } from './store';

const { Item } = Form;
export const ImgTextInfo: React.FC<{ store: StepTwoStore; }> = observer((props) => {
  const { singleUploadImgStore, mainImgsStore, productInfoStore, whitePics, materialPics } = props.store;
  const { deleteImg, selectImg } = singleUploadImgStore;
  return (
    <ContentWrap
      id="imgTextInfo"
      text="图文信息"
    >
      <Row className={styles.imgTextRow}>
        <Col
          className={styles.colContent}
          span={3}
        >
          <span className={styles.requiredTip}>
            *
          </span>
          商品主图
          <span className={styles.colMao}>
            :
          </span>
        </Col>
        <Col span={21}>
          <MainImgs
            singleUploadImgStore={singleUploadImgStore}
            store={mainImgsStore}
          />
        </Col>
      </Row>
      <Item
        label="商品描述"
        name="details"
        required
      >
        <Input.TextArea
          autoSize={{
            minRows: 3,
            maxRows: 12,
          }}
          maxLength={1000}
        />
      </Item>
      <Row className={styles.imgTextRow}>
        <Col
          className={styles.colContent}
          span={3}
        >
          <span className={styles.requiredTip}>
            *
          </span>
          商品详情
          <span className={styles.colMao}>
            :
          </span>
        </Col>
        <Col span={21}>
          <ProductInfo
            productImgTip="单张大小不超过2M，最多上传20张，支持png/jpg/jpeg，拖拽可调整顺序"
            singleUploadImgStore={singleUploadImgStore}
            store={productInfoStore}
          />
        </Col>
        <Col
          className={styles.colContent}
          span={3}
        >
          商品素材:
        </Col>
        <Col
          className={styles.productMaterial}
          span={21}
        >
          素材图用于推荐、活动会场等流量场景，上传素材可提高商品曝光机会，图片尺寸建议800*800，大小不超过2M，支持JPG/JPEG/PNG
          <div className={styles.uploadWrapper}>
            <div className={styles.eachUpload}>
              <SingleUploadImg
                deleteImg={deleteImg}
                fileList={whitePics}
                selectImg={selectImg}
                type="whitePics"
              />
              <div className={styles.eachUploadText}>
                白底图
              </div>
            </div>
            <div className={styles.eachUpload}>
              <SingleUploadImg
                deleteImg={deleteImg}
                fileList={materialPics}
                selectImg={selectImg}
                type="materialPics"
              />
              <div className={styles.eachUploadText}>
                透明图
              </div>
            </div>
          </div>
        </Col>
        {/* <Video store={props.store}/> */}
      </Row>
    </ContentWrap>
  );
});

// 主图视频
const Video: React.FC<{ store: StepTwoStore; }> = observer((props) => {
  const { store } = props;
  return (
    <>
      <Col
        className={styles.colContent}
        span={3}
      >
        主图视频：
      </Col>
      <Col
        span={21}
      >
        <div className={styles.videoTip}>
          请选择关联视频，该视频会在商品主图展示
        </div>
        <SingleUploadVideo
          deleteVideo={() => {
            store.singleUploadImgStore?.deleteImg('videoManage');
          }}
          fileList={store.singleUploadImgStore.videoFileList}
          selectVideo={() => {
            store.singleUploadImgStore?.selectImg('videoManage');
          }}
          type="videoManage"
        />
      </Col>
    </>
  );
});
